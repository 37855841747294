import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DetailsHead from "../components/detailshead"
import BWH from '../components/brand-we-help/index'

const About = () => (
  <Layout>
    <SEO title="Brand We Help: About Car LockSmith Services" />
    <DetailsHead title="Car Makes We Service"/>
    <BWH/>
  </Layout>
)

export default About
