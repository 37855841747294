import React, {useState} from 'react'
import { graphql, useStaticQuery, Link} from "gatsby"

import styles from './brand.module.css'

const BWH = () => {

	const query = useStaticQuery(graphql`
	query {
		  allStrapiBrandWeHelp {
		    edges{
		      node{
		        id
		        title
		        brands{
		          id
		          name
		        }
		      }
		    }
		  }
	  }
	`)

	let p = [];
	query.allStrapiBrandWeHelp.edges.forEach(({node})=>{
		p.push(node)
	})
	p = p[0];

	p.brands.sort(function(a, b) {
	  var keyA = a.name,
	    keyB = b.name;
	  // Compare the 2 dates
	  if (keyA < keyB) return -1;
	  if (keyA > keyB) return 1;
	  return 0;
	});

	return (
		<div className={styles.mainWrapper}>
			<div className={styles.container}>
				<div className={styles.wrapper}>
					<h2 className={styles.brandTitle}>{p.title}</h2>
					<div className={styles.brandWrapper}>
						{
							p.brands.map((brand, index)=>{
								return (
										<div key={index}>{ brand.name }</div>
									)
							})
						}
				</div>
				</div>
			</div>
		</div>
	)
}

export default BWH